<template>
  <div class="add-state-announcement add-template">
    <g-background>
      <page-header id="pageHeader">
        <h1 v-if="stateMode">{{ isEditMode ? translations.tcEditStateEvent : translations.tcAddStateEvent }}</h1>
        <h1 v-else>{{ isEditMode ? translations.tcEditCountryEvent : translations.tcAddCountryEvent }}</h1>
      </page-header>
      <page-body>
        <div class="page-navigation">
          <div class="nav-item nav-item-1" :class="{ active: page > 0 }">
            <div class="icon">
              <geo-dis />
            </div>
            <div class="text">{{ translations.tcGeographicDistribution }}</div>
          </div>
          <div class="sep" :class="{ active: page > 1 }"></div>
          <div class="nav-item nav-item-3" :class="{ active: page > 1 }">
            <div class="icon">
              <message-icon />
            </div>
            <div class="text">{{ translations.tcEvent }}</div>
          </div>
          <div class="sep" :class="{ active: page > 2 }"></div>
          <div class="nav-item nav-item-4" :class="{ active: page > 2 }">
            <div class="icon">
              <review-icon />
            </div>
            <div class="text">{{ translations.tcReview }} &amp;&nbsp;{{ translations.tcSubmit }}</div>
          </div>
        </div>
      </page-body>
      <hr />
      <b-form @submit.prevent v-show="page === 1" class="view-1 g-mb-2">
        <h2 class="h-style-1 mb-3">
          {{ translations.tcDistributionLevel }}
          <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcDistributionLevel">
            <i-tooltip />
          </span>
        </h2>
        <div class="g-mb-1">
          <switch-item :list="distributionList" :key="'distribution'" v-model="distributionSelected"></switch-item>
        </div>
        <h2 class="h-style-1 mb-3" v-if="isSelectedAreas">{{ translations.tcAreas }}</h2>
        <div class="checkbox-row">
          <areas-tiles
            v-if="isSelectedAreas"
            :items="areasOption"
            :headings="'Default'"
            :checkall="isSelectedAreas"
            v-model="selectedAreas"
            :selectedItems="[...selectedItems, ...chosenAreas]"
            :i18n="translations.components"
          ></areas-tiles>
        </div>
      </b-form>
      <b-form @submit.prevent v-show="page === 2" class="view-3 g-mb-2">
        <page-body>
          <b-form class="event-type-form">
            <b-form-group :label="translations.tcEventType" class="font-style-2 mb-0">
              <b-form-select
                v-model="event"
                :options="eventTypeTranslated"
                :state="!!event"
                class="form-control g-select"
              ></b-form-select>
            </b-form-group>
            <b-form-group :label="translations.tcEventTitle" class="font-style-2 mb-0">
              <b-form-input
                v-model="eventTitle"
                :state="event !== GideonOtherMeetingGuid || !!eventTitle"
                id="event-input"
                :placeholder="translations.tcEventTitle"
              ></b-form-input>
            </b-form-group>
          </b-form>
          <b-form class="member-type-form">
            <b-form-group :label="translations.tcMemberType" class="font-style-2 mb-0">
              <b-form-select
                v-model="member"
                :state="!!member"
                :options="memberType"
                class="form-control g-select"
              ></b-form-select>
            </b-form-group>
          </b-form>
          <hr />
          <section class="form-block">
            <div class="h-style-1">{{ translations.tcLocation }}</div>
            <div class="mb-3">
              <b-form-radio-group
                id="radios-location-type"
                v-model="locationTypeSelected"
                :options="locationTypeOptions"
                button-variant="outline-primary"
                name="adios-location-type"
                size="sm"
                buttons
              ></b-form-radio-group>
            </div>
            <div id="loc-physical" v-if="locationTypeSelected === 'physical'">
              <p class="mb-3">{{ locationDisplay }}</p>
              <b-form class="event-type-form">
                <location-search
                  @location-select="handleLocationSelect"
                  :cnt_key="this.userCountryKey"
                  :i18n="translations.components"
                />
                <b-button variant="primary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0 mx-4" @click="addLocation"
                  >{{ translations.tcAddNewLocation }}
                </b-button>
              </b-form>
            </div>
            <div id="loc-virtual" v-if="locationTypeSelected === 'virtual'">
              <p class="mb-3">{{ locationVirtualDisplay }}</p>
              <b-form class="event-type-form">
                <location-virtual-search
                  @location-virtual-select="handleVirtualLocationSelect"
                  :cnt_key="this.userCountryKey"
                  :i18n="translations.components"
                />
                <b-button
                  variant="primary"
                  class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0 mx-0 mt-4 mt-lg-0 mx-lg-4"
                  @click="addLocationVirtual"
                  >{{ translations.tcAddNewVirtualLocation }}
                </b-button>
              </b-form>
            </div>
          </section>
          <section class="form-block">
            <div class="h-style-1">{{ translations.tcCoordinator }}</div>
            <b-container class="bv-example-row">
              <b-row class="text-center">
                <b-col cols="4">
                  <model-select
                    class="form-control"
                    :options="coordinators"
                    v-model="coordinator"
                    :placeholder="translations.tcSelectCoordinator"
                    @input="applyNewCoordinator($event)"
                  >
                  </model-select>
                </b-col>
              </b-row>
            </b-container>
          </section>
          <section class="form-block">
            <b-form>
              <div class="h-style-1" v-if="recurrenceType === 'multiple' || !recurrenceType">
                {{ translations.tcDateRecurrence }}
              </div>
              <b-form-checkbox type="checkbox" v-model="isMultiDayEvent" v-if="!recurrenceType">
                {{ translations.tcCheckIfRecurring }}
              </b-form-checkbox>
              <b-form-group
                :label="isMultiDayEvent ? translations.tcStartEndDate : translations.tcDate"
                class="font-style-2 mt-3 mb-0"
              >
                <div id="dpContainer" class="dp-container">
                  <div id="dpStart" class="dp-startend">
                    <b-form-datepicker
                      id="startDate"
                      v-model="startEndDate.startDate"
                      class="mb-2 contact_date"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                      :locale="prefCulture"
                      :label-help="translations.tcCalHelp"
                      :label-no-date-selected="translations.tcDatePickerPlaceholder"
                      :placeholder="translations.tcDatePickerPlaceholder"
                      :show-decade-nav="showDecadeNav"
                      :hide-header="hideHeader"
                      size="sm"
                    >
                      <template #button-content style="padding: 12px">
                        <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                      </template>
                    </b-form-datepicker>
                  </div>
                  <div id="dpEnd" class="dp-startend">
                    <b-form-datepicker
                      id="endDate"
                      v-model="startEndDate.endDate"
                      class="mb-2 contact_date"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                      :locale="prefCulture"
                      :label-help="translations.tcCalHelp"
                      :label-no-date-selected="translations.tcDatePickerPlaceholder"
                      :placeholder="translations.tcDatePickerPlaceholder"
                      :show-decade-nav="showDecadeNav"
                      :hide-header="hideHeader"
                      size="sm"
                      v-if="isMultiDayEvent"
                    >
                      <template #button-content style="padding: 12px">
                        <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                      </template>
                    </b-form-datepicker>
                  </div>
                </div>
              </b-form-group>
              <b-form-group :label="translations.tcStartTimeEndTime" class="font-style-2 mb-0">
                <div id="dpContainer" class="dp-container">
                  <div id="dpStart" class="dp-startend">
                    <b-form-timepicker
                      v-model="startTime"
                      minutes-step="5"
                      :locale="prefCulture"
                      :label-close-button="translations.tcClose"
                      :label-no-time-selected="translations.tcNoTimeSelected"
                    >
                    </b-form-timepicker>
                  </div>
                  <div id="dpStart" class="dp-startend">
                    <b-form-timepicker
                      v-model="endTime"
                      minutes-step="5"
                      :locale="prefCulture"
                      :label-close-button="translations.tcClose"
                      :label-no-time-selected="translations.tcNoTimeSelected"
                    >
                    </b-form-timepicker>
                  </div>
                </div>
              </b-form-group>
              <div v-if="recurrenceType === 'multiple' || (!recurrenceType && isMultiDayEvent)">
                <b-form-group :label="translations.tcSelectFrequency" label-class="freqLabel" class="mt-3">
                  <b-form-radio-group
                    id="btn-radios-frequency"
                    v-model="rSettings.freq"
                    :options="selectedFrequencyOptions"
                    button-variant="outline-primary"
                    size="lg"
                    name="btn-radios-frequency"
                    buttons
                    @change="changereoccurstate($event)"
                  ></b-form-radio-group>
                </b-form-group>
                <b-form-group class="form-element every-week-on" v-show="rSettings.freq === 'daily'" ref="daily">
                  <span>{{ translations.tcEvery }}</span>
                  <b-form-input
                    v-model="rSettings.config.daily.every"
                    class="px-3 every-input"
                    placeholder=""
                  ></b-form-input>
                  <span>{{ translations.tcDayss }}</span
                  ><!-- tcDayss is not a typo -->
                </b-form-group>
                <b-form-group class="form-element every-week-on" v-show="rSettings.freq === 'weekly'" ref="weekly">
                  <span>{{ translations.tcEvery }}</span>
                  <b-form-input
                    v-model="rSettings.config.weekly.every"
                    class="px-3 every-input"
                    placeholder=""
                  ></b-form-input>
                  <span>{{ translations.tcWeeksOn }}</span>
                  <b-form-checkbox-group
                    id="checkbox-group-3"
                    v-model="rSettings.config.weekly.days"
                    :options="days"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>
                <b-form-group class="form-element every-week-on" v-show="rSettings.freq === 'monthly'" ref="monthly">
                  <b-form-radio
                    @change="clearType"
                    v-model="rSettings.config.monthly.type"
                    value="type_1"
                    name="option"
                    checked="checked"
                  ></b-form-radio>
                  <span>{{ translations.tcDay }}</span>
                  <b-form-input
                    v-model="rSettings.config.monthly.mtype_1.day"
                    class="mx-2 px-3 every-input"
                    placeholder=""
                  ></b-form-input>
                  <span>{{ translations.tcOfEvery }}</span>
                  <b-form-input
                    v-model="rSettings.config.monthly.mtype_1.months"
                    class="mx-2 px-3 every-input"
                    placeholder=""
                  ></b-form-input>
                  <span>{{ translations.tcMonths }}</span>
                </b-form-group>
                <b-form-group class="form-element every-week-on" v-show="rSettings.freq === 'monthly'" ref="monthly">
                  <b-form-radio
                    @change="clearType"
                    v-model="rSettings.config.monthly.type"
                    value="type_2"
                    name="option"
                  ></b-form-radio>
                  <span>{{ translations.tcThe }}</span>
                  <b-form-select
                    v-model="rSettings.config.monthly.mtype_2.when"
                    :options="ordinal"
                    class="form-control g-select"
                  ></b-form-select>
                  <b-form-select
                    v-model="rSettings.config.monthly.mtype_2.weekday"
                    :options="days"
                    class="form-control g-select mx-2"
                  ></b-form-select>
                  <span>{{ translations.tcOfEvery }}</span>
                  <b-form-input
                    v-model="rSettings.config.monthly.mtype_2.months"
                    class="mx-2 every-input"
                  ></b-form-input>
                  <span>{{ translations.tcMonths }}</span>
                </b-form-group>
                <b-form-group class="form-element every-week-on" v-show="rSettings.freq === 'yearly'" ref="yearly">
                  <span>{{ translations.tcRecurEvery }}</span>
                  <b-form-input v-model="rSettings.config.yearly.years" class="mx-2 px-3 every-input"></b-form-input>
                  <span>{{ translations.tcYear }}</span>
                </b-form-group>
                <b-form-group class="form-element every-week-on" v-show="rSettings.freq === 'yearly'" ref="yearly">
                  <b-form-radio
                    v-model="rSettings.config.yearly.type"
                    id="_option2"
                    name="_option"
                    value="type_1"
                    @change="clearTypeYear"
                  ></b-form-radio>
                  <span for="_option2">{{ translations.tcOn }}</span>
                  <b-form-select
                    v-model="rSettings.config.yearly.ytype_1.month"
                    :options="months"
                    class="form-control g-select mx-2"
                  ></b-form-select>
                  <b-form-input
                    v-model="rSettings.config.yearly.ytype_1.day"
                    class="mx-2 px-3 every-input"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="form-element every-week-on" v-show="rSettings.freq === 'yearly'" ref="yearly">
                  <b-form-radio
                    v-model="rSettings.config.yearly.type"
                    id="_option1"
                    name="_option"
                    value="type_2"
                    @change="clearTypeYear"
                  ></b-form-radio>
                  <span for="_option1">{{ translations.tcOnThe }}</span>
                  <b-form-select
                    v-model="rSettings.config.yearly.ytype_2.when"
                    :options="ordinal"
                    class="form-control g-select"
                  ></b-form-select>
                  <b-form-select
                    v-model="rSettings.config.yearly.ytype_2.weekday"
                    :options="days"
                    class="form-control g-select mx-2"
                  ></b-form-select>
                  <span>{{ translations.tcOf }}</span>
                  <b-form-select
                    v-model="rSettings.config.yearly.ytype_2.month"
                    :options="months"
                    class="form-control g-select mx-2"
                  ></b-form-select>
                </b-form-group>
              </div>
            </b-form>
          </section>
        </page-body>
      </b-form>
      <b-form @submit.prevent v-show="page === 3" class="view-4 g-mb-2">
        <div class="row d-flex">
          <div class="col col-a">
            <section>
              <h2 class="h-style-1">{{ translations.tcEventType }}</h2>
              <p>
                {{
                  eventTypeTranslated.find(x => x.value === event)
                    ? eventTypeTranslated.find(x => x.value === event).text
                    : ''
                }}
              </p>
            </section>
            <section>
              <h2 class="h-style-1">{{ translations.tcEventTitle }}</h2>
              <p>{{ eventTitle }}</p>
            </section>
            <section>
              <h2 class="h-style-1">{{ translations.tcMemberType }}</h2>
              <p>
                {{
                  memberType.filter(x => x.value === member)[0]
                    ? memberType.filter(x => x.value === member)[0].text
                    : ''
                }}
              </p>
            </section>
            <section>
              <h2 class="h-style-1">{{ translations.tcEventLocation }}</h2>
              <span v-if="!!defaultlocation">{{ defaultlocation }}</span>
              <span v-if="!!defaultlocationVirtual"> {{ defaultlocationVirtual }}</span>
              <p v-if="virtualLocation && virtualLocation.lvt_url" class="mt-0">
                {{ virtualLocation.lvt_url }}<br />
                {{ virtualLocation.lvt_note }}
              </p>
              <p v-if="location && location.adr_line1" class="mt-0">
                {{ location.adr_line1 + ' ' + location.adr_city }}
              </p>
            </section>
            <section>
              <h2 class="h-style-1">{{ translations.tcCoordinator }}</h2>
              <p v-if="!!coordinator">{{ coordinatorName }}</p>
            </section>
            <section>
              <h2 class="h-style-1">{{ translations.tcEventDates }}</h2>
              <div class="calendar" v-if="!!startEndDate">
                <iCalendar />
                {{
                  new Date(startEndDate.startDate + 'T01:02:03').toLocaleString(this.prefCulture, { dateStyle: 'long' })
                }}
                <span v-if="startEndDate.startDate !== startEndDate.endDate"
                  >--
                  {{
                    new Date(startEndDate.endDate + 'T01:02:03').toLocaleString(this.prefCulture, { dateStyle: 'long' })
                  }}</span
                >
              </div>
            </section>
            <section>
              <div class="calendar" v-if="!!startTime">
                <iClock class="clockIcon"></iClock>
                {{ formatTimeString(startTime) }} -- {{ formatTimeString(endTime) }}
              </div>
            </section>
            <section>
              <div class="calendar" v-if="!!startTime">
                <iCalendar />
                {{ meetingExpression() }}
              </div>
            </section>
          </div>
          <div class="col col-b">
            <section>
              <h2 class="h-style-1">{{ translations.tcGeographicDistribution }}</h2>
              <ul class="no-bullets" v-if="!isSelectedAreas">
                <li v-if="stateMode">{{ translations.tcFullState }}</li>
                <li v-else>{{ translations.tcFullCountry }}</li>
              </ul>
              <section v-if="isSelectedAreas">
                <ul class="no-bullets" v-for="(item, index) in selectedAreas" :key="index">
                  <li>{{ item.org_name }}</li>
                </ul>
              </section>
            </section>
          </div>
        </div>
      </b-form>
      <section class="form-buttons">
        <b-button variant="primary" class="mr-3" v-if="page > 1 && page < 3" @click="handleBackClick()">
          {{ translations.tcBack }}
        </b-button>
        <b-button variant="primary" class="mr-3" v-if="page < 3" @click="handleNextClick()" :disabled="activaNext">
          {{ translations.tcNext }}
        </b-button>
        <b-button variant="tertiary" v-if="page < 3" @click="handleCancelClick()">{{ translations.tcCancel }}</b-button>
        <b-button variant="primary" class="mr-3" v-if="page === 3 && !isEditMode" @click="handleSaveClick('add')">
          {{ translations.tcSubmit }}
        </b-button>
        <b-button variant="primary" class="mr-3" v-if="page === 3 && isEditMode" @click="handleSaveClick('update')">
          {{ translations.tcUpdate }}</b-button
        >
        <b-button variant="tertiary" v-if="page === 3" @click="page = 1">{{ translations.tcEdit }}</b-button>
      </section>
    </g-background>
    <add-meeting-location @locationSelectionMade="applyNewLocation($event)" v-if="translations" :i18n="translations" />
    <add-meeting-location-virtual
      @locationVirtualSelectionMade="applyNewLocationVirtual($event)"
      :i18n="translations"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AddMeetingLocation from '@/components/AddMeetingLocation.vue'
import AddMeetingLocationVirtual from '@/components/AddMeetingLocationVirtual.vue'
import AreaTiles from '@/components/AreasTiles.vue'
import audienceIcon from '@/assets/svgs/audience-icon.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import constants from '@/json/data.json'
import CustomeLocationSearch from '@/components/CustomeLocationSearch.vue'
import LocationSearch from '@/components/calendar/calendar-search/LocationSearch.vue'
import LocationVirtualSearch from '@/components/calendar/calendar-search/LocationVirtualSearch.vue'
import date_data from '@/json/date.json'
import gBackground from '@/components/gBackground.vue'
import GeoDis from '@/assets/svgs/geographic-distribution-icon.vue'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iClock from '@/assets/svgs/iClock.vue'
import iPaperclip from '@/assets/svgs/iPaperclip.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { Loader } from '@/mixins/Loader'
import messageIcon from '@/assets/svgs/message-icon.vue'
import { ModelSelect } from 'vue-search-select'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import reviewIcon from '@/assets/svgs/review-icon.vue'
import SearchableDropdown from '@/components/SearchableDropdown.vue'
import SwitchItem from '@/components/SwitchItem.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'state-event-geographic-dist',
  mixins: [Loader, translationMixin, numericMixin, calendarMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      recurrenceType: null,
      isEditMode: false,
      isMultiDayEvent: false,
      stateMode: true,
      defaultlocation: null,
      defaultlocationVirtual: null,
      defaultTime: null,
      dist: false,
      selectedItems: [],
      prevRoute: null,
      activaNext: false,
      audienceSwtich: false,
      distributionList: [
        { text: 'Full State', selected: true, key: 'full' },
        { text: 'Selected Areas', selected: false, key: 'areas' }
      ],
      distributionSelected: null,
      audienceList: null,
      audienceSelected: null,
      isSelectedAreas: false,
      memberTypeSelected: null,
      selectedAreas: [],
      page: 1,
      selected_areas: [],
      selectedFrequency: null,
      gideon: [],
      auxiliary: [],
      selected: null,
      event: null,
      org_key: null,
      selectLocation: null,
      selectLocationVirtual: null,
      startEndDate: { startDate: null, endDate: null },
      startEndTime: null,
      startTime: null,
      endTime: null,
      rSettings: {
        freq: null,
        config: {
          daily: {
            every: null
          },
          weekly: {
            every: null,
            days: []
          },
          monthly: {
            type: null,
            mtype_1: {
              day: null,
              months: null
            },
            mtype_2: {
              when: null,
              weekday: null,
              months: null
            }
          },
          yearly: {
            years: null,
            type: null,
            ytype_1: {
              month: null,
              day: null
            },
            ytype_2: {
              when: null,
              weekday: null,
              month: null
            }
          }
        }
      },
      location: null,
      virtualLocation: null,
      locationTypeSelected: 'physical',
      locationTypeOptions: [],
      errorMessage: null,
      dailyshowstate: false,
      weekshowstate: false,
      monthshowstate: false,
      yearshowstate: false,
      onceshowstate: false,
      validateError: [],
      swap: {
        _dailyshowstate: 'outline-primary',
        _weekshowstate: 'outline-primary',
        _monthshowstate: 'outline-primary',
        _yearshowstate: 'outline-primary',
        _onceshowstate: 'primary'
      },
      coordinator: null,
      // coordinatorName: null,
      coordinators: [],
      event: null,
      eventTitle: null,
      eventType: [{ value: null, text: 'Event Type:' }],
      eventTypeTranslated: [],
      member: null,
      memberType: [{ value: null, text: 'Member Type:' }],
      day: 'Wednesday',
      reoccurs: [
        { value: 1, text: 'First' },
        { value: 2, text: 'Second' },
        { value: 3, text: 'Third' },
        { value: 4, text: 'Fourth' }
      ],
      month: 'January',
      translations: {},
      CalendarEventSaveMessage: {
        tcScheduleWasAddedSuccessfully: 'tcScheduleWasAddedSuccessfully',
        tcScheduleWasUpdatedSuccessfully: 'tcScheduleWasUpdatedSuccessfully',
        tcSelectedScheduleDoesNotContainAnyRecurrence: 'tcSelectedScheduleDoesNotContainAnyRecurrence'
      },
      GideonOtherMeetingGuid: '6fd79c51-e6d1-49bd-86f2-a384c7c082b4'
    }
  },
  async created() {
    try {
      const results = await Promise.all([
        this.getViewTranslations('buttons', 'date-time', 'forms'),
        this.getComponentTranslations(
          'add-meeting-location',
          'add-meeting-location-virtual',
          'areas-tiles',
          'custome-location-search',
          'location-search',
          'location-virtual-search'
        ),
        this.getComponentTranslations('common.calendar-event-type-state')
      ])

      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common }
      }
      this.$set(this.translations, 'components', translatedText)

      this.loadTranslations()
      this.setLocationTypeOptions()
      await this.page_load()
    } catch (error) {
      console.error('Error', error)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  watch: {
    distributionSelected(newval, oldval) {
      if (newval.selected) {
        if (newval.key === 'full') {
          this.isSelectedAreas = false
          this.activaNext = false
        } else {
          this.isSelectedAreas = true
          this.activaNext = !this.selectedAreas.length > 0
        }
      }
    },
    location(newval, oldvalue) {
      if (typeof newval !== 'string' && this.isEditMode) {
        this.defaultlocation = this.location ? this.location.org_name : null
      }
    },
    selectedAreas(newval, oldval) {
      if (newval.length > 0) {
        this.activaNext = false
      } else {
        this.activaNext = true
      }
    },
    audienceSelected(newval, oldval) {
      if (newval.selected) {
        this.audienceSwtich = newval.key === 'camp' ? true : false
      }
    },
    isMultiDayEvent(newval, oldval) {
      if (!newval) {
        this.changereoccurstate(null)
      }
    },
    rSettings: {
      handler(value, oldvalue) {},
      deep: true
    }
    // coordinator(value, oldvalue) {
    //   if (this.coordinators.length > 0 && value.length > 0) {
    //     this.coordinatorName = this.coordinators.find((r) => r.value === value).text
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      areas: 'eventCalendar/getAreas',
      areasOption: 'eventCalendar/areasOption',
      campOfficerGroup: 'eventCalendar/campOfficerGroup',
      demograph: 'user/demograph',
      getEditType: 'eventCalendar/getEditType',
      meetingSelected: 'eventCalendar/meetingSelected',
      meetingTypeList: 'eventCalendar/meetingTypeList',
      memberTypeList: 'eventCalendar/memberTypeList',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userCountryKey: 'user/userCountryKey',
      userOrgKey: 'user/userOrgKey'
    }),
    coordinatorName() {
      if (this.coordinators.length > 0 && this.coordinator) {
        return this.coordinators.find(r => r.value === this.coordinator).text
      }
      return null
    },
    days() {
      return date_data.days.map(dd => {
        return { value: dd.weekday, text: this.translate(dd.text) }
      })
    },
    months() {
      return date_data.months.map(mm => {
        return { value: mm.value, text: this.translate(mm.text) }
      })
    },
    ordinal() {
      return date_data.ordinal.map(o => {
        return { value: o.value, text: this.translate(o.text) }
      })
    },
    chosenAreas() {
      // Exclude any areas already included in the selected Items list.
      let newAreas = this.selectedAreas.filter(area => {
        let selectedItem = this.selectedItems.filter(selected => {
          return selected.org_key.toUpperCase() === area.org_key.toUpperCase()
        })
        return selectedItem.length === 0
      })
      return newAreas.map(item => {
        return {
          org_key: item.org_key
        }
      })
    },
    locationDisplay() {
      if (!this.location && !this.defaultlocation)
        return this.translations.tcNoLocationSelected || 'No Location Selected'
      if (!!this.location && !!this.location.org_name) return this.location.org_name
      return this.defaultlocation
    },
    locationVirtualDisplay() {
      if (!this.virtualLocation && !this.defaultlocationVirtual) {
        return this.translations.tcNoLocationSelected || 'No Location Selected'
      } else if (!!this.virtualLocation && !!this.virtualLocation.lvt_name) {
        return this.virtualLocation.lvt_name
      } else {
        return this.defaultlocationVirtual
      }
    },
    selectedFrequencyOptions() {
      let options = [
        { text: this.translations.tcOnce, value: 'once' },
        { text: this.translations.tcDaily, value: 'daily' },
        { text: this.translations.tcWeekly, value: 'weekly' },
        { text: this.translations.tcMonthly, value: 'monthly' },
        { text: this.translations.tcYearly, value: 'yearly' }
      ]
      return options
    }
  },
  methods: {
    ...mapActions({
      addUpdateStateEvent: 'eventCalendar/addUpdateStateEvent',
      getEventCalenderFormSettings: 'eventCalendar/getEventCalenderFormSettings',
      getManagementTeam: 'officersModule/getManagementTeam',
      getOrganisationHierarchy: 'eventCalendar/getOrganisationHierarchy',
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    handleCancelClick() {
      this.$router.push({ path: `/calendar/state/event/state-event-view` })
    },
    handleLocationSelect(location) {
      this.location = location
      this.selectLocation = null
      this.defaultlocation = location.org_name
      this.selectLocationVirtual = null
      this.virtualLocation = null
      this.defaultlocationVirtual = null
    },
    handleVirtualLocationSelect(location) {
      this.virtualLocation = location

      this.selectLocationVirtual = null
      this.defaultlocation = null
      this.defaultlocationVirtual = location.lvt_name
      this.selectLocation = constants.virtual_location_org_key
      this.location = null
    },
    handleBackClick() {
      this.page--
      document.getElementById('pageHeader').scrollIntoView()
    },
    setSaveEventTranslatedMessage(msg) {
      var translatedMessage = ''
      if (msg === this.CalendarEventSaveMessage.tcScheduleWasAddedSuccessfully) {
        translatedMessage = this.translations.tcScheduleWasAddedSuccessfully
      } else if (msg === this.CalendarEventSaveMessage.tcScheduleWasUpdatedSuccessfully) {
        translatedMessage = this.translations.tcScheduleWasUpdatedSuccessfully
      } else if (msg === this.CalendarEventSaveMessage.tcSelectedScheduleDoesNotContainAnyRecurrence) {
        translatedMessage = this.translations.tcSelectedScheduleDoesNotContainAnyRecurrence
      }
      return translatedMessage
    },
    handleNextClick() {
      this.validateError.splice(0, this.validateError.length)

      // Perform validation logic based on the current page viewing
      switch (this.page) {
        case 1:
          this.validateGeographicDistribution()
          break
        case 2:
          this.validateEventInformation()
          break
        default:
          break
      }

      if (this.validateError.length === 0) {
        this.page++
        document.getElementById('pageHeader').scrollIntoView()
      } else {
        this.$swal({
          icon: 'error',
          text: `${this.translations.tcErrorAllFieldsRequired} '${this.validateError.join(', ')}'`,
          confirmButtonText: this.translations.tcOk || 'Ok'
        }).then(result => {
          return
        })
      }
    },
    setProps(obj, newVal) {
      for (var i in obj) {
        if (typeof obj[i] === 'object' && !(obj[i] instanceof Array)) {
          this.setProps(obj[i], newVal)
        } else {
          obj[i] = newVal
        }
      }
    },
    clearType(type) {
      this.setProps(this.rSettings.config.monthly, null)
      this.rSettings.config.monthly.type = type
    },
    clearTypeYear(type) {
      this.setProps(this.rSettings.config.yearly, null)
      this.rSettings.config.yearly.type = type
    },
    changereoccurstate(state) {
      this.setProps(this.rSettings, null)
      this.rSettings.freq = state
      switch (state) {
        case 'monthly':
          this.rSettings.config.monthly.type = 'type_1'
          break
        case 'yearly':
          this.rSettings.config.yearly.type = 'type_1'
          break
        case 'weekly':
          this.rSettings.config.weekly.days = []
          break
        default:
          break
      }
    },
    checkIOCStatusAndFilterMeetingType(item) {
      // When `stateMode` is false, we do not want to 2 meeting types (see below), because they are IOC Members
      if (
        this.stateMode ||
        (!this.stateMode &&
        item.mtt_key !== 'bc65d624-07b6-4041-b68b-ca4248df7b4a' && // Gideon State Event
          item.mtt_key !== '48d28140-135f-40e9-b216-0bbae7aa2046') // State Convention
      ) {
        const translatedText =
          this.translations.common['calendar-event-type-state'][item.mtt_key] || item.mtt_description_text
        return { value: item.mtt_key, text: translatedText }
      }
    },
    filterMeetingTypeListPerIOCStatus() {
      return this.meetingTypeList
        .map(item => this.checkIOCStatusAndFilterMeetingType(item))
        .filter(item => !!item && item.value !== '48d28140-135f-40e9-b216-0bbae7aa2046')
    },
    async getEventCalenderSetting(org_key) {
      await this.getEventCalenderFormSettings({ org_key: org_key, event_type: 'state' }).then(result => {
        if (result === true) {
          const filteredMeetingType = this.filterMeetingTypeListPerIOCStatus()
          this.memberType = [
            ...this.memberType,
            ...this.memberTypeList.map(item => {
              return {
                value: item.vmpt_mpt_key,
                text: this.translate(item.vmpt_trn_txt)
              }
            })
          ]

          this.eventTypeTranslated = [...this.eventType, ...filteredMeetingType]
        }
      })
    },
    async getOrgAreas(t_parent_org_key, org_key, lng_key) {
      this.showLoader()

      await this.getOrganisationHierarchy({ t_parent_org_key: t_parent_org_key, org_key: org_key, lng_key: lng_key })
        .then(result => {
          if (result === true) {
            this.getEventCalenderSetting(this.userOrgKey)
          }
        })
        .catch(err => {
          console.error('Error', err)
          this.showErrorMessage(
            this.translations.components['loader'].tcInternetConnectionLost,
            this.translations.components['loader'].tcRetry,
            this.getEventCalenderSetting
          )
        })
        .finally(() => {
          this.closeLoader()
        })
    },
    async applyNewLocation(evt) {
      this.selectLocation = evt.key
      this.location = evt.key
      this.defaultlocation = evt.name
      this.selectLocationVirtual = null
      this.defaultlocationVirtual = null
      this.virtualLocation = null
    },
    async applyNewLocationVirtual(evt) {
      this.selectLocationVirtual = evt.lvt_key
      this.virtualLocation = evt
      this.defaultlocationVirtual = evt.name
      this.selectLocation = constants.virtual_location_org_key
      this.location = null
      this.defaultlocation = null
    },
    async applyNewCoordinator(evt) {
      this.coordinator = evt
      // this.coordinatorName = this.coordinators.find((r) => r.value === evt).text
    },
    async handleSaveClick(addEdit) {
      this.validateError = []
      this.validateGeographicDistribution()
      this.validateEventInformation()
      if (this.validateError.length > 0) {
        this.errorMessage = `${this.translations.tcErrorAllFieldsRequired} '${this.validateError.join(', ')}'`

        return false // return false to prevent form submission
      }
      let addNewMeetings = {
        start_date: this.startEndDate.startDate,
        end_date: this.startEndDate.endDate,
        mtg_title: this.eventTitle ? this.eventTitle : '',
        mtg_start_time: this.startTime,
        mtg_end_time: this.endTime,
        mtg_coordinator_ind_key: this.coordinator,
        mtg_mpt_key: this.member,
        mtg_mtt_key: this.event,
        org_key: this.demograph.state_key,
        freq: this.rSettings.freq,
        config: this.rSettings.config,
        state_key: this.demograph.state_key,
        mtr_key: addEdit === 'update' ? this.meetingSelected.mtrkey : null,
        mtg_location_org_key: this.location && this.location.org_key ? this.location.org_key : this.selectLocation,
        mtg_location_virtual_key: !this.virtualLocation
          ? this.selectLocationVirtual
          : this.virtualLocation.key
          ? this.virtualLocation.key
          : this.virtualLocation.lvt_key
      }

      if (addEdit === 'update' && this.recurrenceType === 'single') {
        addNewMeetings.mtg_key = this.meetingSelected.id
        addNewMeetings.is_update = {
          mtr_key: this.meetingSelected.mtrkey
        }
      }

      if (this.isSelectedAreas && this.selectedAreas.length > 0) {
        addNewMeetings.distrubtion = this.selectedAreas.map(item => {
          return {
            mgd_children_org_key: item.org_key,
            mgd_parent_org_key: item.region_key
          }
        })
      } else {
        addNewMeetings.distrubtion = [
          {
            mgd_children_org_key: this.demograph.state_key,
            mgd_parent_org_key: this.demograph.state_key
          }
        ]
      }
      this.showLoader()
      await this.addUpdateStateEvent(addNewMeetings)
        .then(res => {
          if (res.status === 200) {
            this.$swal({
              text: this.setSaveEventTranslatedMessage(res.data.message),
              icon: 'success',
              confirmButtonText: this.translations.tcOk
            })
            this.$router.push({
              path: '/calendar/state/event/state-event-view'
            })
          } else {
            this.$swal({
              text: this.translations.tcErrorOperationFailed,
              icon: 'error',
              confirmButtonText: this.translations.tcOk || 'Ok'
            })
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.closeLoader()
        })
    },
    setLocationTypeOptions() {
      this.locationTypeOptions = [
        {
          text: this.translations.tcPhysicalLocation ? this.translations.tcPhysicalLocation : 'Physical Location',
          value: 'physical'
        },
        {
          text: this.translations.tcVirtualLocation ? this.translations.tcVirtualLocation : 'Virtual Location',
          value: 'virtual'
        }
      ]
    },
    validateGeographicDistribution() {
      if (!!this.distributionSelected && this.distributionSelected.key === 'areas' && this.selectedAreas.length === 0) {
        this.validateError.push(this.translations.tcSelectedAreas || 'Selected Areas')
        this.errorMessage = this.translations.tcSelectAtLeastOneArea || 'Select at least one area.'
      }
    },
    validateEventInformation() {
      if (!this.event) {
        this.validateError.push(this.translate('Event Type'))
      }

      if (!this.member) {
        this.validateError.push(this.translate('Member Type'))
      }

      if (this.event === this.GideonOtherMeetingGuid && !this.eventTitle) {
        this.validateError.push(this.translate('Event Title'))
      }

      if (!this.location && !this.selectLocation) {
        this.validateError.push(this.translate('Location'))
      }

      if (!this.coordinator) {
        this.validateError.push(this.translate('Coordinator'))
      }

      if (!!this.startEndDate.startDate && !this.isMultiDayEvent) {
        this.startEndDate.endDate = this.startEndDate.startDate
      }

      if (!this.startEndDate || !this.startEndDate.startDate || !this.startEndDate.endDate) {
        this.validateError.push(this.translate('Start And End Date'))
      }

      if (!this.startTime || !this.endTime) {
        this.validateError.push(this.translate('Start And End Time'))
      }

      switch (this.rSettings.freq) {
        case 'daily':
          if (!this.rSettings.config.daily || !this.rSettings.config.daily.every) {
            this.validateError.push(this.translate('Dayss')) // `Dayss` is not a typo, it translates to 'Day(s)'
          }
          break
        case 'weekly':
          if (!this.rSettings.config.weekly || !this.rSettings.config.weekly.every) {
            this.validateError.push(this.translate('Every Number Weeks')) // Every # Week(s)
          }
          if (!this.rSettings.config.weekly || !this.rSettings.config.weekly.days) {
            this.validateError.push(this.translate('Dayss')) // `Dayss` is not a typo, it translates to 'Day(s)'
          }
          break
        case 'monthly':
          if (this.rSettings.config.monthly.type === 'type_1') {
            if (!this.rSettings.config.monthly.mtype_1 || !this.rSettings.config.monthly.mtype_1.day) {
              this.validateError.push(this.translate('Day'))
            }
            if (!this.rSettings.config.monthly.mtype_1 || !this.rSettings.config.monthly.mtype_1.months) {
              this.validateError.push(this.translate('Months')) // Month(s)
            }
          }
          if (this.rSettings.config.monthly.type === 'type_2') {
            if (!this.rSettings.config.monthly.mtype_2 || !this.rSettings.config.monthly.mtype_2.when) {
              this.validateError.push(this.translate('Week'))
            }
            if (!this.rSettings.config.monthly.mtype_2 || !this.rSettings.config.monthly.mtype_2.weekday) {
              this.validateError.push(this.translate('Weekday'))
            }
            if (!this.rSettings.config.monthly.mtype_2 || !this.rSettings.config.monthly.mtype_2.months) {
              this.validateError.push(this.translate('Months')) // Month(s)
            }
          }
          break
        case 'yearly':
          if (!this.rSettings.config.yearly.years) {
            this.validateError.push(this.translate('Recur Every Number Years')) // Recur Every # Year(s)
          }
          if (this.rSettings.config.yearly.type === 'type_1') {
            if (!this.rSettings.config.yearly.ytype_1 || !this.rSettings.config.yearly.ytype_1.month) {
              this.validateError.push(this.translate('Month'))
            }
            if (!this.rSettings.config.yearly.ytype_1 || !this.rSettings.config.yearly.ytype_1.day) {
              this.validateError.push(this.translate('Day'))
            }
          }
          if (this.rSettings.config.yearly.type === 'type_2') {
            if (!this.rSettings.config.yearly.ytype_2 || !this.rSettings.config.yearly.ytype_2.when) {
              this.validateError.push(this.translate('Week'))
            }
            if (!this.rSettings.config.yearly.ytype_2 || !this.rSettings.config.yearly.ytype_2.weekday) {
              this.validateError.push(this.translate('Weekday'))
            }
            if (!this.rSettings.config.yearly.ytype_2 || !this.rSettings.config.yearly.ytype_2.month) {
              this.validateError.push(this.translate('Month'))
            }
          }
          break
        default:
          break
      }

      if (this.validateError.length > 0) {
        this.errorMessage = `${this.translations.tcErrorAllFieldsRequired} '${this.validateError.join(', ')}'`
      }
    },
    addLocation() {
      this.$root.$emit('bv::show::modal', 'addMeetingLocationModal')
    },
    addLocationVirtual() {
      this.$root.$emit('bv::show::modal', 'addMeetingLocationVirtualModal')
    },
    editMode() {
      if (!!this.meetingSelected) {
        this.recurrenceType = this.getEditType !== null ? this.getEditType.type : null
        this.isEditMode = true
        this.getRecurrenceMeeting()

        if (this.officerToolbarSelected.camp_number.substring(0, 1) === 'U') {
          this.stateMode = true
          this.distributionList = [
            { text: 'Full State', selected: !this.meetingSelected.distribution, key: 'full' },
            { text: 'Selected Areas', selected: !!this.meetingSelected.distribution, key: 'areas' }
          ]
        } else {
          this.stateMode = false
          this.distributionList = [
            { text: 'Full Country', selected: !this.meetingSelected.distribution, key: 'full' },
            { text: 'Selected Areas', selected: !!this.meetingSelected.distribution, key: 'areas' }
          ]
        }

        if (!!this.meetingSelected.distribution) {
          this.isSelectedAreas = true
          this.activaNext = false

          this.selectedItems = this.meetingSelected.distribution.map(item => {
            return { org_key: item.mgd_children_org_key }
          })
        } else {
          this.isSelectedAreas = false
          this.selectedItems = []
        }
      } else {
        this.recurrenceType = null
        if (this.officerToolbarSelected.camp_number.substring(0, 1) === 'U') {
          this.stateMode = true
          this.distributionList = [
            { text: 'Full State', selected: true, key: 'full' },
            { text: 'Selected Areas', selected: false, key: 'areas' }
          ]
        } else {
          this.stateMode = false
          this.distributionList = [
            { text: 'Full Country', selected: true, key: 'full' },
            { text: 'Selected Areas', selected: false, key: 'areas' }
          ]
        }
      }
    },
    getRecurrenceMeeting() {
      if (!!this.meetingSelected.reccurrencetype && (this.recurrenceType === 'multiple' || !this.recurrenceType)) {
        this.isMultiDayEvent = this.recurrenceType === 'multiple'
        let reocurrence = this.meetingSelected.reccurrencetype
        this.eventTitle = reocurrence.mtr_title
        this.member = reocurrence.mtr_mpt_key
        this.event = reocurrence.mtr_mtt_key
        this.coordinator = reocurrence.mtr_coordinator_ind_key
        // this.coordinatorName = reocurrence.vin_formal_name
        this.selectLocation = reocurrence.mtr_location_org_key
        this.location = {
          org_key: reocurrence.mtr_location_org_key,
          org_name: this.meetingSelected.location.org_name,
          adr_city: this.meetingSelected.location.adr_city,
          adr_line1: this.meetingSelected.location.adr_line1,
          adr_line2: this.meetingSelected.location.adr_line2,
          adr_line3: this.meetingSelected.location.adr_line3,
          adr_postal_code: this.meetingSelected.location.adr_postal_code,
          adr_state: this.meetingSelected.location.adr_state
        }
        this.locationVirtual = { ...this.meetingSelected.locationVirtual }
        if (this.locationVirtual.lvt_key) {
          this.selectLocationVirtual = this.locationVirtual.lvt_key
          this.locationTypeSelected = 'virtual'
        }
        this.startEndDate = {
          startDate: this.formatForDatePicker(reocurrence.mtr_recurrence_date_range_begin_date),
          endDate: this.formatForDatePicker(reocurrence.mtr_recurrence_date_range_end_date)
        }
        this.defaultTime = {
          startTime: reocurrence.mtr_recurrence_date_range_begin_date.split('T')[1],
          endTime: reocurrence.mtr_recurrence_date_range_end_date.split('T')[1]
        }
        this.startTime = reocurrence.mtr_recurrence_date_range_begin_date.split('T')[1]
        this.endTime = reocurrence.mtr_recurrence_date_range_end_date.split('T')[1]
        this.defaultlocation = reocurrence.location
        this.defaultlocationVirtual = this.meetingSelected.locationVirtual.lvt_name
        this.changereoccurstate(reocurrence.vmrt_trn_txt.toLowerCase())
        this.setUpEditInfo(reocurrence.vmrt_trn_txt.toLowerCase(), reocurrence)
      } else {
        this.eventTitle = this.meetingSelected.mtg_title
        this.member = this.meetingSelected.mtg_mpt_key
        this.event = this.meetingSelected.mtg_mtt_key || this.meetingSelected.meetingtype
        this.coordinator = this.meetingSelected.mtg_coordinator_ind_key
        this.selectLocation = this.meetingSelected.mtg_location_org_key
        this.location = {
          org_key: this.meetingSelected.mtg_location_org_key,
          org_name: this.meetingSelected.location.org_name,
          adr_city: this.meetingSelected.location.adr_city,
          adr_line1: this.meetingSelected.location.adr_line1,
          adr_line2: this.meetingSelected.location.adr_line2,
          adr_line3: this.meetingSelected.location.adr_line3,
          adr_postal_code: this.meetingSelected.location.adr_postal_code,
          adr_state: this.meetingSelected.location.adr_state
        }
        this.locationVirtual = { ...this.meetingSelected.locationVirtual }
        if (this.locationVirtual.lvt_key) {
          this.selectLocationVirtual = this.locationVirtual.lvt_key
          this.locationTypeSelected = 'virtual'
        }
        this.startEndDate = {
          startDate: this.formatForDatePicker(this.meetingSelected.mtg_start_date || this.meetingSelected.start),
          endDate: this.formatForDatePicker(this.meetingSelected.mtg_end_date || this.meetingSelected.end)
        }
        this.defaultTime = {
          startTime: !!this.meetingSelected.mtg_start_date
            ? this.meetingSelected.mtg_start_date.split('T')[1]
            : this.meetingSelected.start.split('T')[1],
          endTime: !!this.meetingSelected.mtg_end_date
            ? this.meetingSelected.mtg_end_date.split('T')[1]
            : this.meetingSelected.end.split('T')[1]
        }
        this.startTime = !!this.meetingSelected.mtg_start_date
          ? this.meetingSelected.mtg_start_date.split('T')[1]
          : this.meetingSelected.start.split('T')[1]
        this.endTime = !!this.meetingSelected.mtg_end_date
          ? this.meetingSelected.mtg_end_date.split('T')[1]
          : this.meetingSelected.end.split('T')[1]
        this.defaultlocation = this.meetingSelected.location.org_name
        this.defaultlocationVirtual = this.meetingSelected.locationVirtual.lvt_name
      }
    },
    setUpEditInfo(type, data) {
      switch (type) {
        case 'weekly':
          {
            this.rSettings.config.weekly.every = data.mtr_recurrence_day_of_week_or_month
            this.rSettings.config.weekly.days = data.mtr_recurrence_specific_days_of_week.split(',')
          }
          break
        case 'daily':
          {
            this.rSettings.config.daily.every = data.mtr_recurrence_frequency
          }
          break
        case 'monthly':
          {
            this.rSettings.config.monthly.type = data.mtr_option
            if (data.mtr_option === 'type_1') {
              this.rSettings.config.monthly.mtype_1.day = data.mtr_recurrence_frequency
              this.rSettings.config.monthly.mtype_1.months = data.mtr_recurrence_month_frequency
            } else {
              this.rSettings.config.monthly.mtype_2.when = data.mtr_recurrence_frequency
              this.rSettings.config.monthly.mtype_2.weekday = parseInt(data.mtr_recurrence_day_of_week_or_month)
              this.rSettings.config.monthly.mtype_2.months = data.mtr_recurrence_month_frequency
            }
          }
          break
        case 'yearly':
          {
            this.rSettings.config.yearly.type = data.mtr_option
            this.rSettings.config.yearly.years = data.mtr_recurrence_year_frequency
            if (data.mtr_option === 'type_1') {
              this.rSettings.config.yearly.ytype_1.day = data.mtr_recurrence_frequency
              this.rSettings.config.yearly.ytype_1.month = data.mtr_recurrence_month_frequency
            } else {
              this.rSettings.config.yearly.ytype_2.when = data.mtr_recurrence_frequency
              this.rSettings.config.yearly.ytype_2.month = data.mtr_recurrence_month_frequency
              this.rSettings.config.yearly.ytype_2.weekday = data.mtr_recurrence_day_of_week_or_month
            }
          }
          break
        default:
          break
      }
    },
    meetingExpression() {
      if (!this.rSettings.freq) return this.translations.tcOnce || 'Once'
      if (this.rSettings.freq === 'weekly') {
        let _weekdays = !!this.rSettings.config.weekly.days
          ? this.rSettings.config.weekly.days.map(item => {
              return this.days.filter(x => x.value === parseInt(item))[0].text
            })
          : []
        return `${this.translations.tcEvery} ${this.rSettings.config.weekly.every} ${
          this.translations.tcWeek
        }(s) ${_weekdays.join(',')}`
      } else if (this.rSettings.freq === 'daily') {
        if (this.rSettings.config.daily.every === 1) {
          return this.translations.tcEveryDay
        } else {
          return `${this.translations.tcEvery} ${this.rSettings.config.daily.every} ${this.translations.tcDays}`
        }
      } else if (this.rSettings.freq === 'once') {
        return this.translations.tcOnce
      } else if (this.rSettings.freq === 'monthly') {
        let ret = ''
        if (this.rSettings.config.monthly.type === 'type_1') {
          ret += `${this.translations.tcEvery} ${this.rSettings.config.monthly.mtype_1.day} ${this.translations.tcDay} `
          ret += `- ${this.translations.tcEvery} ${this.rSettings.config.monthly.mtype_1.months} ${this.translations.tcMonth}(s) `
        } else {
          ret += `- ${this.translations.tcEvery} ${
            this.reoccurs.filter(x => x.value === parseInt(this.rSettings.config.monthly.mtype_2.when))[0].text
          } `
          if (!!this.rSettings.config.monthly.mtype_2.weekday) {
            ret += `- ${
              this.days.filter(x => x.value === parseInt(this.rSettings.config.monthly.mtype_2.weekday))[0].text
            } `
          }
          ret += `- ${this.translations.tcEvery} ${this.rSettings.config.monthly.mtype_2.months} ${this.translations.tcMonth}(s) `
        }
        return ret
      } else if (this.rSettings.freq === 'yearly') {
        let ret = ''
        if (this.rSettings.config.yearly.type === 'type_1') {
          ret += `${this.translations.tcEvery} ${this.rSettings.config.yearly.years} ${this.translations.tcYear}(s) `
          if (!!this.rSettings.config.yearly.ytype_1.month) {
            ret += `- ${this.months.filter(x => x.value === this.rSettings.config.yearly.ytype_1.month)[0].text} `
          }
          ret += `- ${this.translations.tcEvery} ${this.rSettings.config.yearly.ytype_1.day} ${this.translations.tcDay} `
        } else {
          ret += `${this.translations.tcEvery} ${this.rSettings.config.yearly.years} ${this.translations.tcYear}(s) `
          ret += `- ${this.translations.tcEvery} ${this.rSettings.config.yearly.ytype_2.when} ${this.translations.tcWeek} `
          if (!!this.rSettings.config.yearly.ytype_2.weekday) {
            ret += `- ${
              this.days.filter(x => x.value === parseInt(this.rSettings.config.yearly.ytype_2.weekday))[0].text
            } `
          }
          if (!!this.rSettings.config.yearly.ytype_2.month) {
            ret += `- ${this.months.filter(x => x.value === this.rSettings.config.yearly.ytype_2.month)[0].text} `
          }
        }
        return ret
      }
    },
    async page_load() {
      this.setLoadingStatus(true)
      try {
        await this.getOrgAreas(
          this.officerToolbarSelected.country_state || this.demograph.country_state,
          this.demograph.camp_key,
          this.demograph.language_key
        )
        this.editMode()

        if (this.officerToolbarSelected.camp_number.substring(0, 1) === 'U') {
          this.stateMode = true
          this.distributionList[0].text = this.translations.tcFullState || 'Full State'
        } else {
          this.stateMode = false
          this.distributionList[0].text = this.translations.tcFullCountry || 'Full Country'
        }
        this.distributionList[1].text = this.translations.tcSelectedAreas || 'Selected Areas'
        this.eventType[0].text = this.translations.tcEventType + ':'
        this.memberType[0].text = this.translations.tcMemberType + ':'

        //load coordinator list
        this.response = await this.getManagementTeam()
        if (typeof this.response === 'object') {
          let list1 = []
          let list2 = []
          let list3 = []
          let list4 = []
          if (!!this.response.gideon) {
            if (!!this.response.gideon.gideon) {
              list1 = this.GideonLeadershipList(this.response.gideon.gideon)
            }
            if (!!this.response.gideon.gideon_mgt) {
              list2 = this.GideonLeadershipList(this.response.gideon.gideon_mgt)
            }
          }
          if (!!this.response.aux) {
            if (!!this.response.aux.aux) {
              list3 = this.GideonLeadershipList(this.response.aux.aux)
            }
            if (!!this.response.aux.aux_mgt) {
              list4 = this.GideonLeadershipList(this.response.aux.aux_mgt)
            }
          }
          //combine leadership lists
          this.coordinators = [...list1, ...list2, ...list3, ...list4]

          //remove duplicates
          const dedupedCoordinators = Array.from(new Set(this.coordinators.map(a => a.value))).map(value => {
            return this.coordinators.find(a => a.value === value)
          })
          this.coordinators = [...dedupedCoordinators]

          //sort list
          this.coordinators.sort((a, b) => (a.sort_name > b.sort_name ? 1 : b.sort_name > a.sort_name ? -1 : 0))
        }
      } finally {
        this.setLoadingStatus(false)
      }
    },
    GideonLeadershipList(teamList) {
      if (!teamList) {
        return null
      }
      let result = teamList.map(obj => {
        return {
          value: obj.ind_key,
          text: obj.name,
          sort_name: obj.sort_name
        }
      })
      return result
    },
    loadTranslations() {
      if (this.officerToolbarSelected.camp_number.substring(0, 1) === 'U') {
        this.stateMode = true
        this.distributionList[0].text = this.translations.tcFullState
      } else {
        this.stateMode = false
        this.distributionList[0].text = this.translations.tcFullCountry
      }
      this.distributionList[1].text = this.translations.tcSelectedAreas
      this.eventType[0].text = this.translations.tcEventType
      this.memberType[0].text = this.translations.tcMemberType
    },
    translate(text) {
      // helper function to shorten a translation lookup
      // text should be a value that exists in `this.translations`, IE 'monday', 'january', 'third'
      // This method will convert the `text` into something like `tcMonday`, `tcJanuary`, `tcThird`
      const trnsTxt = this.translations[this.convertValForTranslation(text)]
      return trnsTxt // this.translations[this.convertValForTranslation(text)]
    }
  },

  components: {
    'add-meeting-location': AddMeetingLocation,
    'add-meeting-location-virtual': AddMeetingLocationVirtual,
    'areas-tiles': AreaTiles,
    audienceIcon: audienceIcon,
    'custome-location': CustomeLocationSearch,
    gBackground: gBackground,
    GeoDis: GeoDis,
    iCalendar: iCalendar,
    iClock: iClock,
    iPaperclip: iPaperclip,
    iTooltip: iTooltip,
    LocationVirtualSearch,
    messageIcon: messageIcon,
    'page-body': pageBody,
    'page-header': pageHeader,
    reviewIcon: reviewIcon,
    SearchableDropdown: SearchableDropdown,
    'switch-item': SwitchItem,
    ModelSelect,
    LocationSearch
  }
}
</script>

<style lang="scss">
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
// form template for add-template on AddCampAnnouncement.vue USE AddCampAnnouncement as include
.freqLabel {
  font-weight: bold;
}
.add-template {
  h1 {
    margin-bottom: 34px;
  }
  hr {
    margin: 60px 0 35px;
  }
  @include breakpoint(sm) {
    .page-header {
      text-align: center;
      h1 {
        font-size: 42px;
      }
    }
  }
  .button-group {
    .btn-primary {
      border: 1px solid #003946;
      text-transform: uppercase !important;
    }
  }
  .g-background {
    margin: 90px auto 104px;
  }
  .form-section {
    font-size: 22px;
    font-weight: bold;
    display: block;
    line-height: 44px;
    letter-spacing: 0.73px;
    text-transform: uppercase;
    color: #636363;
    margin-bottom: 10px;
  }
  .form-block {
    .form-element {
      margin-bottom: 10px;

      &.form-attachment {
        margin-bottom: 60px;
        margin-top: 45px;
        @include breakpoint(sm) {
          margin-top: 25px;
        }
      }
    }
  }
  .form-control {
    height: auto;
    font-weight: normal;
    text-transform: none;
    line-height: 25px;
    padding: 10px 20px;
    border-color: #636363;
    color: #636363;
  }
  .custom-checkbox {
    padding-left: 34px;
    line-height: 24px;
    margin-bottom: 10px;
    label {
      font-size: 16px;
      &:before {
        top: 0;
        left: -34px;
        border-radius: 0;
        width: 24px;
        height: 24px;
        border-color: #636363;
      }
      &:after {
        top: 0;
        left: -34px;
        height: 24px;
        width: 24px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #003946;
    }
    label.custom-control-label {
      min-width: 200px;
    }
  }
  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        width: 100%;
        margin-bottom: 15px !important;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

// 1060
// 410
// ----
// 650

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.add-state-announcement {
  .g-mb-1 {
    margin-bottom: 42px;
  }
  .g-mb-2 {
    margin-bottom: 60px;
    @include breakpoint(sm) {
      margin-bottom: 45px;
    }
  }
  .i-tooltip {
    vertical-align: super;
    svg path {
      fill: #000 !important;
    }
  }
  .view-1 {
    @include breakpoint(sm) {
      .btn-group {
        width: 100%;
      }
    }
  }
  .view-2 {
    .custom-control-inline {
      display: flex;
      margin-left: 30px;
      margin-bottom: 0;
      align-items: center;
      @include breakpoint(sm) {
        margin-left: 0;
      }
    }
    @include breakpoint(sm) {
      .btn-group {
        flex-wrap: wrap;
        width: 100%;
        .btn {
          width: 50%;
          flex: 0 1 auto;
          padding: 12px 5px;
        }
      }
      .member-type {
        flex-wrap: wrap;
        .btn-group {
          margin-bottom: 30px;
          @include breakpoint(sm) {
            // margin-bottom: ;
          }
          .btn {
            width: 33%;
          }
        }
      }
    }
  }
  .view-3 {
    input::placeholder {
      color: #636363;
      font-weight: bold;
    }
  }
  .view-4 {
    .col {
      display: block;
    }
    .col-a {
      max-width: 60%;
      flex-basis: 60%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 30px;
      }
    }
    .col-b {
      max-width: 40%;
      flex-basis: 40%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    section {
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 22px;
      font-size: 16px;
      line-height: 22px;
    }
    .form-attachment {
      margin-bottom: 10px;
    }
    .calendar {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
        path {
          fill: #636363 !important;
        }
      }
    }
  }
  .send-notifications {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .form-attachment {
    .attachment-list {
      margin-bottom: 20px;
      span {
        margin: 0 30px 0 10px;
        color: #1e68fb;
      }
      svg:last-of-type {
        width: 14px;
        height: 14px;
      }
      @include breakpoint(sm) {
        .btn {
          width: auto !important;
        }
      }
    }
  }
}
.page-navigation {
  display: flex;
  align-items: center;
  padding: 30px 80px 100px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px 0 30px;
  }
  .nav-item {
    position: relative;
    @include breakpoint(sm) {
      width: 100%;
    }
    .icon {
      svg {
        fill: #8c8c8c !important;
      }
    }
    &.active {
      .icon {
        background-color: $secondary-color;
        svg {
          fill: #000000 !important;
        }
      }
      .text {
        color: #000;
      }
    }
  }
  .sep {
    height: 9px;
    flex: 1 1 auto;
    background-color: #ccc;
    &.active {
      background-color: $secondary-color;
    }
    @include breakpoint(sm) {
      position: relative;
      height: 30px;
      width: 9px;
      flex: 0 0 auto;
      left: 27px;
      transform: translateX(-50%);
    }
  }
  .icon {
    position: relative;
    height: 54px;
    width: 54px;
    flex: 0 0 auto;
    background-color: #ccc;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    display: block;
    position: absolute;
    width: 140px;
    top: 100%;
    left: 50%;
    margin-top: 13px;
    transform: translateX(-50%);
    color: #8c8c8c;
    font-family: $open-sans;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    @include breakpoint(sm) {
      width: calc(100% - 70px);
      left: 70px;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
.checkbox-row {
  display: flex;
  flex-wrap: wrap;
  .checkbox-block {
    min-width: 275px;
    margin-right: 20px;
    padding: 24px 20px 35px 30px;
    // border: 1px solid #e9e9e9;
    border-radius: 6px;
    // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  .bg-block-h {
    display: flex;
    margin-bottom: 15px;
    h3 {
      flex: 1 1 auto;
      margin: 0;
      color: $gray-200;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: initial;
      line-height: 22px;
      text-transform: none;
    }
    .toggle {
      height: 25px;
      width: 25px;
      flex: 0 0 auto;
      border: 2px solid $gray-200;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 2px;
        border-radius: 2px;
        transform: rotate(45deg);
        transform-origin: bottom;
        background-color: $gray-200;
        right: -1px;
        position: relative;
      }
      &:after {
        left: -1px;
        right: auto;
        transform: rotate(-45deg);
      }
    }
  }
  label {
    font-size: 16px;
    line-height: 22px;
  }
}

.event-type-form,
.member-type-form {
  display: flex;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .custom-select {
    margin-right: 20px;
    width: 200px;
    @include breakpoint(sm) {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
    }
  }
  #event-input {
    width: 340px;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}
.member-type-form {
  margin-bottom: 40px;
}
.event-type-form {
  margin-bottom: 10px;
  @include breakpoint(sm) {
    .custom-select {
      margin-bottom: 10px;
    }
  }
}
.coordinator {
  display: flex;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .form-control {
    margin-right: 20px !important;
    @include breakpoint(sm) {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
    &.custom-select {
      width: 300px;
      @include breakpoint(sm) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.form-block {
  margin-bottom: 55px;
}

.every-week-on {
  > div {
    display: flex;
    align-items: center;
    .every-input {
      width: 55px;
    }
    span {
      font-weight: bold;
      text-transform: uppercase;
      &:first-of-type {
        margin-right: 10px;
      }
      &:last-of-type {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .custom-select {
      width: 180px;
    }
  }
}

.dp-container {
  display: flex;
  .dp-startend {
    width: 50%;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}
.contact_date {
  width: 100%;
  @include breakpoint(sm) {
    width: 100%;
  }
}
</style>
